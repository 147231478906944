import authClient from "../../infra/auth/auth-client";
import history from "../../infra/router/history";

export function login (userData) {
 
    return async function (dispatch, getState) {
   
        delete userData.senha;

        authClient.setData(userData);
 
        dispatch({
            type: "AUTH.LOGIN_SUCCESS",
            payload: {
                ...userData
            }
        });

        history.replace("/");
    } 
}


export function logout() {

    return async function (dispatch, getState) {

        authClient.logout();

        history.replace("/login");

        dispatch({
            type: "AUTH.LOGOUT",
        })
    } 
}