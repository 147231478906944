import React from "react";
import SemPermissaoPage from "./SemPermissaoPage";
import { Route, Switch } from "react-router-dom";

function Routes() {

    return (
        <Switch>
            <Route exact path="/erro/sem-permissao" component={SemPermissaoPage} />
        </Switch>
    )
}

export default Routes;