import React from "react";
import Skeleton from "react-loading-skeleton";
import "./page.scss";
import Header from "./Header";
import Filtro from "./Filtro";
import Container from "./Container";

function Page({ loading, children }) {
  return (
    <div className={"page"}>
      {loading ? <Skeleton count={5} /> : null}

      <div> {children} </div>
    </div>
  );
}

Page.Header = Header;
Page.Filtro = Filtro;
Page.Container = Container;

export default Page;
