import React from "react";
import Page from "../../../../shared/layout/page";
import Button from "../../../../shared/fields/Button";
import { Card, Row, Col, Badge } from "react-bootstrap";
import SimpleGrid from "../../../../shared/data/SimpleGrid";
import axios from "axios";
import Filtro from "./components/Filtro";
import moment from "moment";
import history from "../../../../../infra/router/history";

class GrupoLista extends React.PureComponent {
  state = {
    filtro: {},
    loading: false,
    data: {}
  };

  clearFiltro() {
    if (this.state.formRef) {
      this.state.formRef.reset();
    }

    this.setState(
      {
        filtro: {
          ID_TIPO_DESPESA: null
        }
      },
      () => this.getData()
    );
  }

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    const filtro = this.state.filtro;

    this.setState({
      loading: true
    });

    try {
      const { data } = await axios.get("/grupo", {
        params: filtro
      });

      this.setState({
        data: { ...data }
      });
    } catch (e) {
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  async onChangeFiltro(prop, value) {
    if (prop === "ID_COMARCA") {
      this.state.filtro.ID_LOTACAO = null;
    }

    if (prop === "per_page") {
      this.state.filtro.page = 1;
    }

    return this.setState({
      filtro: {
        ...this.state.filtro,
        [prop]: value
      }
    });
  }

  async filtrar(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    return await this.getData();
  }

  render() {
    const { data, filtro, loading } = this.state;

    return (
      <Page>
        <Page.Header>
          <Page.Container>
            <Page.Header.Title>
              <h3>Cadastro de Grupo </h3>
            </Page.Header.Title>
            <Page.Header.Right>
              <Button
                allowed={"CADASTRO_GRUPO.incluir"}
                variant="success"
                style={{
                  marginRight: 16
                }}
                onClick={() => history.push("/cadastro/grupo/novo")}
              >
                Incluir
              </Button>
              <Button onClick={() => this.clearFiltro()}>Limpar</Button>
            </Page.Header.Right>
          </Page.Container>
        </Page.Header>

        <Row>
          <Col sm={12}>
            <Page.Container>
              <Page.Filtro>
                <Filtro
                  onFormRef={ref => this.setState({ formRef: ref })}
                  loading={loading}
                  onFilter={async e => {
                    await this.onChangeFiltro("page", 1);
                    this.filtrar(e);
                  }}
                  filtro={filtro}
                  onChangeFiltro={(prop, value) =>
                    this.onChangeFiltro(prop, value)
                  }
                />
              </Page.Filtro>
            </Page.Container>
          </Col>
        </Row>

        <br />

        <Row>
          <Col sm={12}>
            <Page.Container>
              <Card style={{ width: "100%" }}>
                <Card.Body>
                  <SimpleGrid
                    onChangeFilter={async (prop, value) => {
                      await this.onChangeFiltro(prop, value);
                      this.filtrar();
                    }}
                    loading={loading}
                    permissionRoot="CADASTRO_GRUPO"
                    columns={[
                      {
                        title: "#",
                        name: "ID_GRUPO",
                        style: {
                          width: 70,
                          textAlign: "center"
                        }
                      },
                      {
                        title: "Descrição",
                        name: "DESCRICAO"
                      },
                      {
                        title: "Data de Criação",
                        name: "DATA_CRIACAO",
                        style: {
                          width: 190
                        },
                        render: row =>
                          moment(row.DATA_CRIACAO).format("DD/MM/YYYY hh:mm")
                      },
                      {
                        title: "Situação",
                        style: {
                          width: 120,
                          textAlign: "center"
                        },
                        render: row => {
                          if (row.SITUACAO === 1) {
                            return <Badge variant="success">Ativo</Badge>;
                          }

                          return <Badge variant="danger">Inativo</Badge>;
                        }
                      }
                    ]}
                    onEdit={row =>
                      this.props.history.push("/cadastro/grupo/" + row.ID_GRUPO)
                    }
                    data={data}
                  />
                </Card.Body>
              </Card>
            </Page.Container>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default GrupoLista;
