import React from "react";
import Page from "../../../../../shared/layout/page";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Table, Skeleton } from "antd";
import moment from "moment";
import InputMoney from "../../../../../shared/fields/InputMoney";
import ModalAlteracao from "./ModalAlteracao";
import ModalAlterarTodasJustificativas from "./ModalAlterarTodasJustificativas";

class TabelaLancamento extends React.Component {
  state = {
    loading: false,
    openForm: false,
    openFormAll: false,
    form: {},
    observers: []
  };

  removerItem = item => {
    const GRUPOS = this.props.data.GRUPOS.filter(
      x => x.ID_GRUPO !== item.ID_GRUPO
    );

    this.props.handleChange("GRUPOS", [...GRUPOS]);
  };

  handleChange(record, prop, value) {
    const { data } = this.props;

    const GRUPOS = data.GRUPOS;

    GRUPOS.forEach(grupo => {
      if (grupo.ID_GRUPO === record.ID_GRUPO) {
        grupo[prop] = value;
      }

      if (grupo.VALOR_FINANCEIRO && !grupo.DATA_LANCAMENTO) {
        grupo.DATA_LANCAMENTO = moment().format("YYYY-MM-DD");
      }
    });

    this.props.handleChange("GRUPOS", [...GRUPOS]);
  }

  async salvarAlteracao(form) {
    this.handleChange(form, "VALOR_FINANCEIRO", form.VALOR_FINANCEIRO);
    this.handleChange(form, "JUSTIFICATIVA", form.JUSTIFICATIVA);

    this.setState({
      openForm: false
    });
  }

  editarJustificativa(item) {
    this.setState({
      form: item,
      openForm: true
    });
  }

  editarJustificativaTodos() {
    this.setState({
      openFormAll: true
    });
  }

  alterarTodasJustificativas(value) {
    const { data, handleChange } = this.props;

    data.GRUPOS.forEach(grupo => (grupo.JUSTIFICATIVA = value));

    handleChange("GRUPOS", [...data.GRUPOS]);
  }

  render() {
    const { data, loading } = this.props;
    const { openForm, form, openFormAll } = this.state;

    return (
      <div>
        <ModalAlteracao
          data={{ ...form }}
          open={openForm}
          onClose={() => this.setState({ openForm: false })}
          onChange={f => this.salvarAlteracao(f)}
        />

        <ModalAlterarTodasJustificativas
          open={openFormAll}
          onClose={() => this.setState({ openFormAll: false })}
          onChange={val => this.alterarTodasJustificativas(val)}
        />

        <Row>
          <Col sm={12}>
            <Page.Container>
              <Card style={{ width: "100%" }}>
                <Skeleton loading={loading} active>
                  <Card.Header>
                    <span> Resultados </span>

                    <div style={{ float: "right" }}>
                      <Button
                        size="sm"
                        disabled={!data.GRUPOS.length}
                        onClick={() => this.editarJustificativaTodos()}
                      >
                        Justificar todos
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Table
                      pagination={true}
                      columns={[
                        {
                          title: "Descrição",
                          dataIndex: "DESCRICAO"
                        },
                        {
                          title: "Valor Financeiro",
                          width: 160,
                          dataIndex: "VALOR_FINANCEIRO",
                          render: (value, record, index) => (
                            <InputMoney
                              value={value}
                              disabled={record.ID_LANCAMENTO}
                              id={"id-" + index}
                              onChange={v =>
                                this.handleChange(record, "VALOR_FINANCEIRO", v)
                              }
                            />
                          )
                        },
                        {
                          title: "Data de Lançamento",
                          width: 170,
                          dataIndex: "DATA_LANCAMENTO",
                          render: value =>
                            value ? moment(value).format("DD/MM/YYYY") : null
                        },
                        {
                          title: "Justificativa",
                          dataIndex: "JUSTIFICATIVA",
                          render: value => value || "--"
                        },
                        {
                          title: "Açōes",
                          width: 130,
                          render: (value, record) => (
                            <div>
                              <Button
                                tabIndex="-1"
                                variant="primary"
                                onClick={() => this.editarJustificativa(record)}
                                size="sm"
                                style={{ marginRight: 16 }}
                              >
                                <i className="mdi mdi-pencil" />
                              </Button>

                              <Button
                                tabIndex="-1"
                                variant="danger"
                                onClick={() => this.removerItem(record)}
                                size="sm"
                              >
                                <i className="mdi mdi-delete" />
                              </Button>
                            </div>
                          )
                        }
                      ]}
                      dataSource={data.GRUPOS}
                    />
                  </Card.Body>
                </Skeleton>
              </Card>
            </Page.Container>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TabelaLancamento;
