import React from "react";
import GrupoLista from "./Grupo/GrupoLista";
import GrupoPage from "./Grupo/GrupoPage";
import { Switch } from "react-router-dom";
import RouterAuthorize from "../../../infra/router/RouterAuthorize";
import authClient from "../../../infra/auth/auth-client";

function Routes() {
  return (
    <Switch>
      <RouterAuthorize
        permission={authClient.checkPermission("CADASTRO_GRUPO", "consultar")}
        exact
        path="/cadastro/grupo"
        component={GrupoLista}
      />
      <RouterAuthorize
        exact
        permission={authClient.checkPermission("CADASTRO_GRUPO", "incluir")}
        path="/cadastro/grupo/novo"
        component={GrupoPage}
      />
      <RouterAuthorize
        permission={authClient.checkPermission("CADASTRO_GRUPO", "alterar")}
        exact
        path="/cadastro/grupo/:id"
        component={GrupoPage}
      />
    </Switch>
  );
}

export default Routes;
