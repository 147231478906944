import React from "react";
import { withRouter } from "react-router";
import ReactPaginate from "react-paginate";

class Paginate extends React.Component {
  get getPagination() {
    const { total, per_page, current_page } = this.props;

    const pageCount = Math.ceil(total / per_page);

    return (
      <ReactPaginate
        previousLabel={"anterior"}
        nextLabel={"próximo"}
        pageClassName={"page-item"}
        previousClassName={"page-item page-item-left"}
        nextClassName={"page-item page-item-right"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        breakLabel={<a href="">...</a>}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={4}
        forcePage={current_page - 1}
        onPageChange={this.changePagination.bind(this)}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        firstPageText="primeira"
        lastPageText="última"
        activeClassName={"active"}
      />
    );
  }

  changePagination(e) {
    this.props.onChange(e.selected + 1);
  }

  getClassName() {
    let className = "";

    switch (this.props.align) {
      case "center":
        className += " center-table";
        break;

      case "left":
        className += " float-left";
        break;

      case "right":
        className += " float-right";
        break;

      default:
        className += " center-table";
        break;
    }

    return className;
  }

  render() {
    return (
      <div>
        <div className={this.getClassName()}>{this.getPagination}</div>
      </div>
    );
  }
}

export default withRouter(Paginate);
