import React from "react";
import { Route, Redirect } from "react-router-dom";
import authClient from "../auth/auth-client";

function RouterAuthorize({ ...props }) {
  if (props.permission !== undefined && !props.permission) {
    return <Redirect to="/erro/sem-permissao" />;
  }

  if (!authClient.isLogged()) return <Redirect to="/login" />;

  return <Route {...props} />;
}

export default RouterAuthorize;
