import React from "react";
import Page from "../../../../shared/layout/page";
import Button from "../../../../shared/fields/Button";
import { Card, Form, Row, Col } from "react-bootstrap";
import SimpleGrid from "../../../../shared/data/SimpleGrid";
import axios from "axios";
import InputMoney from "../../../../shared/fields/InputMoney";
import DisplayMoney from "../../../../shared/fields/DisplayMoney";
import Filtro from "./components/Filtro";
import moment from "moment";
import history from "../../../../../infra/router/history";

class DespesaLista extends React.PureComponent {
  state = {
    filtro: {
      DATA: []
    },
    loading: false,
    formRef: null,
    data: {
      data: []
    }
  };

  clearFiltro() {
    if (this.state.formRef) {
      this.state.formRef.reset();
    }

    this.setState({
      filtro: {}
    });

    setTimeout(() => this.getData(), 200);
  }

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    let filtro = { ...this.state.filtro };

    this.setState({
      loading: true
    });

    try {
      let dataInicio = null;
      let dataFim = null;

      if (filtro.DATA) {
        if (filtro.DATA[0]) {
          dataInicio = moment(filtro.DATA[0]).format("YYYY-MM-") + "01";
        }

        if (filtro.DATA[1]) {
          dataFim = moment(moment(filtro.DATA[1]).format("YYYY-MM"))
            .endOf("month")
            .format("YYYY-MM-DD");
        }

        delete filtro.DATA;
      }

      const { data } = await axios.get("/lancamento", {
        params: {
          ...filtro,
          DATA_INICIO: dataInicio,
          DATA_FIM: dataFim
        }
      });

      this.setState({
        data: { ...data },
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false
      });
    }
  }

  async onChangeFiltro(prop, value) {
    if (prop === "ID_COMARCA") {
      this.state.filtro.ID_LOTACAO = null;
    }

    return this.setState({
      filtro: {
        ...this.state.filtro,
        [prop]: value
      }
    });
  }

  async filtrar() {
    return this.getData();
  }

  render() {
    const { loading, data, filtro } = this.state;

    let valorTotal = 0;

    data.data.forEach(item => {
      if (item.VALOR_FINANCEIRO) {
        valorTotal += item.VALOR_FINANCEIRO;
      }
    });

    let valorMedio = valorTotal / data.data.length;

    return (
      <Page>
        <Page.Header>
          <Page.Container>
            <Page.Header.Title>
              <h3> Lançamento de Despesas </h3>
            </Page.Header.Title>
            <Page.Header.Right>
              <Button
                variant="success"
                allowed={"LANCAMENTO_DESPESA.incluir"}
                style={{
                  marginRight: 16
                }}
                onClick={() => history.push("/lancamento/despesa/novo")}
              >
                Lançar
              </Button>
              <Button onClick={() => this.clearFiltro()}>Limpar</Button>
            </Page.Header.Right>
          </Page.Container>
        </Page.Header>

        <Filtro
          onFormRef={ref => this.setState({ formRef: ref })}
          loading={loading}
          onFilter={async () => {
            await this.onChangeFiltro("page", 1);
            this.filtrar();
          }}
          filtro={filtro}
          onChangeFiltro={(prop, value) => this.onChangeFiltro(prop, value)}
        />

        <br />

        <Row>
          <Col sm={12}>
            <Page.Container>
              <Card style={{ width: "100%" }}>
                <Card.Body>
                  <Row>
                    <Col sm={3}>
                      <Form.Group controlId="valorTotal">
                        <Form.Label>Valor Total</Form.Label>
                        <InputMoney
                          disabled={true}
                          value={valorTotal}
                          placeholder="Valor Total"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="valorMedio">
                        <Form.Label>Valor Médio</Form.Label>
                        <InputMoney
                          disabled={true}
                          value={valorMedio}
                          placeholder="Valor Médio"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Page.Container>
          </Col>
        </Row>

        <br />

        <Row>
          <Col sm={12}>
            <Page.Container>
              <Card style={{ width: "100%" }}>
                <Card.Body>
                  <SimpleGrid
                    onChangeFilter={async (prop, value) => {
                      await this.onChangeFiltro(prop, value);
                      this.filtrar();
                    }}
                    permissionRoot="LANCAMENTO_DESPESA"
                    columns={[
                      {
                        title: "Descrição",
                        name: "GRUPO"
                      },
                      {
                        title: "Mes/Ano",
                        name: "MES_ANO",
                        render: record => record.MES + "/" + record.ANO
                      },
                      {
                        title: "Tipo de Despesa",
                        name: "TIPO_DESPESA"
                      },
                      {
                        title: "Valor Financeiro",
                        name: "VALOR_FINANCEIRO",
                        style: {
                          textAlign: "right",
                          width: "130px"
                        },
                        render: record => (
                          <DisplayMoney value={record.VALOR_FINANCEIRO} />
                        )
                      }
                    ]}
                    onEdit={row =>
                      this.props.history.push(
                        "/lancamento/despesa/" + row.ID_LANCAMENTO
                      )
                    }
                    data={data}
                  />
                </Card.Body>
              </Card>
            </Page.Container>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default DespesaLista;
