import { combineReducers } from "redux";
import auth from "./auth.reducer";


const appState = combineReducers({
    auth
});


export default (state, action) => {

    if(action.type === "RESET_APP") {
        state = undefined;
    }

    return appState(state, action);
}


