import { KEY_AUTH } from "../env";

class AuthClient {
  isLogged() {
    return !!this.getData();
  }

  setData(user) {
    localStorage.setItem(
      KEY_AUTH,
      JSON.stringify({
        ...user
      })
    );
  }

  getData() {
    try {
      const result = JSON.parse(localStorage.getItem(KEY_AUTH));

      return result;
    } catch (e) {
      return null;
    }
  }

  checkPermission(chave, permissao) {
    const authData = this.getData();

    if (!authData) return false;

    const permissoes = authData.permissoes || {};

    return !!(permissoes[chave] && permissoes[chave][permissao]);
  }

  logout() {
    localStorage.clear();
  }
}

export default new AuthClient();
