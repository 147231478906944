import React from "react";
import Page from "../../../../../shared/layout/page";
import { Card, Row, Col } from "react-bootstrap";
import SelectTipoDespesa from "../../../../../shared/fields/SelectTipoDespesa";
import { Skeleton, DatePicker } from "antd";
import SelectGroup from "../../../../../shared/fields/SelectGroup";
import Button from "../../../../../shared/fields/Button";

const { MonthPicker } = DatePicker;

class DadosGerais extends React.PureComponent {
  render() {
    const {
      loading,
      disabledPelaImportacao,
      data,
      handleChange,
      invalidForm,
      onLancar
    } = this.props;

    return (
      <div>
        <Row>
          <Col sm={12}>
            <Page.Container>
              <Card style={{ width: "100%" }}>
                <Skeleton loading={loading} active>
                  <Card.Header>Dados da Despesa</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={6}>
                        <SelectGroup
                          controlId="ID_TIPO_DESPESA"
                          label="Tipo Despesa"
                          required
                          error={!data.ID_TIPO_DESPESA && invalidForm}
                        >
                          <SelectTipoDespesa
                            value={data.ID_TIPO_DESPESA}
                            disabled={disabledPelaImportacao}
                            onChange={value =>
                              handleChange("ID_TIPO_DESPESA", value)
                            }
                          />
                        </SelectGroup>
                      </Col>
                      <Col sm={3}>
                        <SelectGroup
                          controlId="MES_ANO"
                          label="Mês/Ano"
                          required
                          error={!data.MES_ANO && invalidForm}
                        >
                          <MonthPicker
                            format="MM/YYYY"
                            style={{
                              marginTop: 2,
                              height: "36px",
                              width: "100%"
                            }}
                            value={data.MES_ANO}
                            onChange={value => handleChange("MES_ANO", value)}
                            placeholder="Selecione..."
                          />
                        </SelectGroup>
                      </Col>

                      <Col sm={3} style={{ paddingTop: 22 }}>
                        <Button
                          type="button"
                          size="sm"
                          onClick={() => onLancar()}
                        >
                          Lançar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Skeleton>
              </Card>
            </Page.Container>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DadosGerais;
