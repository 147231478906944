import React from "react";
import Page from "../../../../shared/layout/page";
import Button from "../../../../shared/fields/Button";
import axios from "axios";
import moment from "moment";
import DadosGerais from "./components/DadosGerais";
import VincularComarca from "./components/VincularComarca";
import { Form } from "react-bootstrap";
import { notification, message } from "antd";
import Erros from "./components/Erros";
import { getErrosValidation } from "../../../../../infra/utils/validation";

class GrupoPage extends React.PureComponent {
  state = {
    filtros: {},
    loading: false,
    loadingSincronizacao: false,
    erros: [],
    invalidForm: false,
    data: {
      SITUACAO: 1,
      DATA_CRIACAO: moment().format("YYYY-MM-DD hh:mm")
    }
  };

  async componentDidMount() {
    const id = this.props.match.params.id;

    await this.sincronizarComarcas();

    await this.setState({
      id
    });

    if (id) {
      setTimeout(() => this.getData(id), 200);
    } else {
      this.setState({
        loading: false
      });
    }
  }

  sincronizarComarcas = async () => {
    this.setState({
      loading: true,
      loadingSincronizacao: true
    });

    try {
      return await axios.post("/comarca/sincronizar");
    } catch (e) {
      return;
    } finally {
      this.setState({
        loadingSincronizacao: false
      });
    }
  };

  handleChange(prop, value) {
    const data = this.state.data;

    if (prop === "VALOR_PADRAO_HABILITADO") {
      if (!value) {
        data.VALOR_PADRAO = 0;
      }
    }

    data[prop] = value;

    this.setState({
      data: {
        ...data
      }
    });
  }

  async save(e) {
    e.preventDefault();
    e.stopPropagation();

    const { data, id } = this.state;

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      this.setState({
        invalidForm: true
      });
      return;
    }

    this.setState({
      loading: true,
      invalidForm: false
    });

    try {
      if (id) {
        await axios.put("/grupo/" + id, data);
      } else {
        await axios.post("/grupo", {
          ...data,
          DATA_CRIACAO: data.DATA_CRIACAO + ":00"
        });
      }

      message.success("Registro salvo com sucesso!");

      this.props.history.push("/cadastro/grupo");
    } catch (error) {
      const { response } = error;

      if (response && response.status === 422) {
        this.setState({
          invalidForm: true,
          erros: getErrosValidation(response)
        });
      } else {
        this.setState({
          erros: [
            "Houve um problema ao realizar a operação. Entre em contato com algum técnico do sistema."
          ]
        });
      }
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  async getData(id) {
    this.setState({
      loading: true
    });

    try {
      const { data } = await axios.get("/grupo/" + id);

      data.DATA_CRIACAO = moment(data.DATA_CRIACAO).format("YYYY-MM-DD H:m");

      this.setState({
        data: { ...data }
      });
    } catch (e) {
      if (e.message.indexOf("404") > -1) {
        notification.warn({
          message: "Aviso",
          description: "O grupo selecionado não existe."
        });
        this.props.history.replace("/cadastro/grupo");
      }
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    const {
      loading,
      data,
      erros,
      invalidForm,
      id,
      loadingSincronizacao
    } = this.state;

    return (
      <Page>
        <Form noValidate validated={invalidForm} onSubmit={e => this.save(e)}>
          <Page.Header>
            <Page.Container>
              <Page.Header.Title>
                <h3>Cadastro de Grupo </h3>
              </Page.Header.Title>
              <Page.Header.Right>
                <Button
                  variant="secondary"
                  type="button"
                  style={{
                    marginRight: 16
                  }}
                  onClick={() => this.props.history.push("/cadastro/grupo")}
                >
                  <i className="mdi mdi-arrow-left" /> Voltar
                </Button>
                <Button disabled={loading} type="submit" variant="success">
                  Salvar
                </Button>
              </Page.Header.Right>
            </Page.Container>
          </Page.Header>

          <Erros erros={erros} />

          <DadosGerais
            data={data}
            id={id}
            invalidForm={invalidForm}
            loading={loading}
            handleChange={(prop, value) => this.handleChange(prop, value)}
          />

          <br />

          {!loadingSincronizacao && (
            <VincularComarca
              data={data}
              invalidForm={invalidForm}
              loading={loading}
              handleChange={(prop, value) => this.handleChange(prop, value)}
            />
          )}
        </Form>
      </Page>
    );
  }
}

export default GrupoPage;
