import React, { Component } from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import PagesRouter from "./ui/pages";
import history from "./infra/router/history";
import Navbar from "./ui/shared/layout/navbar";
import store from "./services/store";
import "antd/dist/antd.css";
import "./infra/styles/theme.scss";
import authClient from "./infra/auth/auth-client";
import { LocaleProvider } from "antd";
import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

class App extends Component {
  componentDidMount() {
    const usuario = authClient.getData();

    store.dispatch({
      type: "AUTH.INITIALIZE",
      payload: {
        ...usuario
      }
    });
  }

  render() {
    return (
      <div className="App">
        <LocaleProvider locale={ptBR}>
          <Provider store={store}>
            <Router history={history}>
              <div>
                <Navbar />
                <PagesRouter />
              </div>
            </Router>
          </Provider>
        </LocaleProvider>
      </div>
    );
  }
}

export default App;
