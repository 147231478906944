import React from "react";
import DespesaLista from "./Despesa/DespesaLista";
import { Switch } from "react-router-dom";
import RouterAuthorize from "../../../infra/router/RouterAuthorize";
import DespesaPage from "./Despesa/DespesaPage";
import authClient from "../../../infra/auth/auth-client";

function Routes() {
  return (
    <Switch>
      <RouterAuthorize
        permission={authClient.checkPermission("LANCAMENTO_DESPESA", "consultar")}
        exact
        path="/lancamento/despesa"
        component={DespesaLista}
      />
      <RouterAuthorize
        permission={authClient.checkPermission("LANCAMENTO_DESPESA", "incluir")}
        exact
        path="/lancamento/despesa/novo"
        component={DespesaPage}
      />
      <RouterAuthorize
        permission={authClient.checkPermission("LANCAMENTO_DESPESA", "alterar")}
        exact
        path="/lancamento/despesa/:id"
        component={DespesaPage}
      />
    </Switch>
  );
}

export default Routes;
