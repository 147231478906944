import React from "react";
import { Form, Button, Image, Alert } from "react-bootstrap";
import Page from "../../shared/layout/page";
import styled from "styled-components"; 
import { connect } from "react-redux";
import jwt_decode from 'jwt-decode';
import axios from "axios"; 
import { login as loginAction } from "../../../services/actions/auth.actions";

const CenterLogin = styled.div`
    max-width: 280px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    padding: 16px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);

    h1 {
        font-size: 22px;
    }
`;

const ContainerLogo = styled.div `
    margin: 30px auto;
    margin-top: 0;
    text-align: center;

    img {
        margin-top: -70px;
    }
`;

class LoginPage extends React.PureComponent {

    state = { 
        validated: false,
        loading: false,
        errors: {},
        errorGlobal: ""
    }

    async logar(event) {

        const { login } = this.props;

        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {

            this.setState({
                loading: true,
                validated: false,
                errorGlobal: ''
            });

            // Vai na Api
            try {

                const usuario = {
                    usuario: form.usuario.value,
                    senha: form.senha.value,
                };

                await login(usuario);

            } catch(error) { 
                
                let message = "Servidor indisponível no momento. Tente mais tarde.";
                
                if(error && error.response && error.response.status === 401) { 

                    message = error.response.data.message;

                    this.setState({
                        validated: true,
                        errorGlobal: message
                    });
                }  

            } finally {

                this.setState({
                    loading: false
                });
            }

            return;
        }

        this.setState({ 
            validated: true,
            errors: {
                login: "Usuário obrigatório",
                senha: "Senha obrigatória"
            } 
        });
    }

    render() {

        const {
            errors, 
            loading,
            errorGlobal,
            validated
        } = this.state;

        return (

            <Page>
                <Page.Container>
                    <CenterLogin>
                        <ContainerLogo> 
                            <Image src="images/logo.png" />

                            <h1>Controle de Despesas</h1>
                        </ContainerLogo>
    
                        <Form
                            noValidate
                            validated={validated} 
                            onSubmit={e => this.logar(e)}>

                            <Form.Group controlId="usuario"> 
                                <Form.Control  
                                    disabled={loading}
                                    placeholder="Usuário" 
                                    required />

                                <Form.Control.Feedback type="invalid">
                                    {errors.login}
                                </Form.Control.Feedback>

                            </Form.Group>

                            <Form.Group controlId="senha"> 

                                <Form.Control   
                                    type="password" 
                                    required
                                    disabled={loading}
                                    placeholder="Senha" />

                                <Form.Control.Feedback type="invalid">
                                    {errors.senha}
                                </Form.Control.Feedback>

                            </Form.Group>

                            <Button disabled={loading} block variant="primary" type="submit">
                                Acessar
                            </Button>
                        </Form>

                        {errorGlobal && <Alert style={{marginTop: 16}}  variant="danger"> {errorGlobal} </Alert>}
                    
                    </CenterLogin>
                </Page.Container>
            </Page> 
        )
    }
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch, props) => ({

    async login(usuario) { 

        const response = await axios.post( "/login", usuario); 

        if(response.status === 200) {

            const token = response.data;

            const userInfo = jwt_decode(token); 
 
            usuario.token = token; 

            return dispatch(loginAction({
                ...usuario, 
                ...userInfo 
            }));
        }       
    } 
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage); 