import React from "react";
import CurrencyInput from "react-currency-input";

class InputMoney extends React.PureComponent {
  render() {
    const { value, onChange, onRef, ...props } = this.props;

    return (
      <CurrencyInput
        {...props}
        ref={ref => onRef && onRef(ref)}
        value={value}
        decimalSeparator=","
        thousandSeparator="."
        className="form-control"
        style={{
          textAlign: "right"
        }}
        onChangeEvent={(event, maskedvalue, floatvalue) =>
          onChange(floatvalue, event)
        }
      />
    );
  }
}

export default InputMoney;
