import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import Page from "../../../shared/layout/page";

function SemPermissaoPage() {
  return (
    <Page>
      <Container style={{ marginTop: 32 }}>
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <h2 style={{ textAlign: "center" }}>
                  Você não possui permissão para acessar este recurso!
                </h2>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default SemPermissaoPage;
