import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
//import { createLogger } from "redux-logger";
import reducers from "./reducers";


//const logger = createLogger();

const composeEnhancers = 
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        //
    }) : compose;


const middlewares = [thunk];

//middlewares.push(logger);


const enhances = composeEnhancers(
    applyMiddleware(...middlewares)
)

export default createStore(
    reducers, 
    enhances
);
