import React from "react";
import { Breadcrumb } from "react-bootstrap";
import "./page.scss";
import history from "../../../../infra/router/history";

function go(path) {
    if(!path) return;
    history.push(path);
}

function Header({children}) {

    return(
        <div className={"page-header"}>
            {children}
        </div>
    )
}

function Title({children, breadcrumb}) {

    breadcrumb = null;

    return( 
        <div className={"page-header-left"}>
            {children}

            {breadcrumb ? (
            <Breadcrumb>
                {breadcrumb.map((item, key) => <Breadcrumb.Item  key={key} onClick={() => go(item[1])} active={key === breadcrumb.length - 1}> {item[2] ? <i className={item[2]} /> : null } {item[0]}</Breadcrumb.Item>)}
            </Breadcrumb>) : null}
        </div>  
    )
}

function Right({children}) {

    return( 
        <div className={"page-header-right"}>
            {children}
        </div>  
    )
} 

Header.Title = Title;
Header.Right = Right;

export default Header;