import React from "react";
import axios from "axios";
import Select from "./Select";

class SelectLotacao extends React.PureComponent {
  state = {
    dados: [],
    loading: false
  };

  async componentWillMount() {
    const { comarcaId } = this.props;

    if (!comarcaId) {
      this.setState({
        dados: []
      });
      return;
    }

    this.getData(comarcaId);
  }

  async componentWillUpdate(nextProps) {
    if (nextProps.comarcaId !== this.props.comarcaId) {
      if (!nextProps.comarcaId) {
        this.setState({
          dados: []
        });
      } else {
        if (this.props.isMulti) {
          this.props.onChange([]);
        } else {
          this.props.onChange(null);
        }

        this.getData(nextProps.comarcaId);
      }
    }
  }

  async getData(comarcaId) {
    let { sincronizar, params = {} } = this.props;

    params = {
      comarcaId,
      ...params
    };

    if (sincronizar) {
      params.sincronizar = sincronizar;
    }

    this.setState({
      loading: true
    });

    const response = await axios.get("/lotacao", {
      params: params
    });

    this.setState({
      dados: response.data,
      loading: false
    });
  }

  getOptions() {
    return this.state.dados.map(x => {
      return {
        value: x.id,
        label: x.descricao
      };
    });
  }

  render() {
    const { loading } = this.state;
    const { ...props } = this.props;

    return <Select loading={loading} {...props} options={this.getOptions()} />;
  }
}

export default SelectLotacao;
