import React from "react";
import { Form, Button, Modal } from "react-bootstrap";

class ModalAlterarTodasJustificativas extends React.PureComponent {
  state = {
    form: {}
  };

  async salvar() {
    const { form } = this.state;
    const { onChange, onClose } = this.props;

    onChange(form.JUSTIFICATIVA);

    onClose(true);

    this.setState({ form: {} });
  }

  handleChangeLocal(prop, value) {
    this.setState({
      form: {
        ...this.state.form,
        [prop]: value
      }
    });
  }

  render() {
    const { form } = this.state;
    const { open, onClose } = this.props;

    return (
      <div>
        <Modal show={open} onHide={() => onClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Adicionar Justificativa</Modal.Title>
          </Modal.Header>

          <Modal.Body>
             
              <Form.Group controlId="JUSTIFICATIVA">
                <Form.Label>Justificativa</Form.Label>

                <Form.Control
                  as="textarea"
                  rows="5"
                  value={form.JUSTIFICATIVA}
                  onChange={e =>
                    this.handleChangeLocal("JUSTIFICATIVA", e.target.value)
                  }
                />
              </Form.Group>
             
          </Modal.Body>

          <Modal.Footer>
            <Button type="button" onClick={() => onClose()} variant="secondary">
              Cancelar
            </Button>
            <Button
              type="button"
              onClick={() => this.salvar()}
              variant={"success"}
            >
              <i className={"mdi mdi-check"} /> Salvar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ModalAlterarTodasJustificativas;
