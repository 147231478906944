import React from "react";
import Page from "../../../../../shared/layout/page";
import SITUACAO_GRUPO from "../../../../../../services/static/SituacaoGrupo";
import { Card, Form, Row, Col } from "react-bootstrap";
import SelectTipoDespesa from "../../../../../shared/fields/SelectTipoDespesa";
import Switch from "react-switch";
import InputMoney from "../../../../../shared/fields/InputMoney";
import { Skeleton } from "antd";
import moment from "moment";
import InputGroup from "../../../../../shared/fields/InputGroup";
import SelectGroup from "../../../../../shared/fields/SelectGroup";
import LabelRequired from "../../../../../shared/fields/LabelRequired";

class DadosGerais extends React.PureComponent {
  get hasEnergia() {
    const { data } = this.props;

    return !!(data.TIPOS_DESPESAS || []).filter(x => x === 7)[0]; //Tipo despesa energia
  }

  render() {
    const { loading, data, handleChange, invalidForm, id } = this.props;

    return (
      <div>
        <Row>
          <Col sm={12}>
            <Page.Container>
              <Card style={{ width: "100%" }}>
                <Skeleton loading={loading} active>
                  <Card.Header>Dados Gerais</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={6}>
                        <InputGroup
                          disabled={loading}
                          controlId="DESCRICAO"
                          label={"Descrição"}
                          maxLength={150}
                          required
                          value={data.DESCRICAO}
                          onChange={e =>
                            handleChange("DESCRICAO", e.target.value)
                          }
                        />
                      </Col>
                      <Col sm={3}>
                        <InputGroup
                          label="Data Criação"
                          controlId="DATA_CRIACAO"
                          value={moment(data.DATA_CRIACAO).format(
                            "DD/MM/YYYY hh:mm"
                          )}
                          disabled={true}
                        />
                      </Col>
                      {id ? (
                        <Col sm={3}>
                          <InputGroup
                            as="select"
                            label="Situação"
                            controlId="SITUACAO"
                            disabled={loading}
                            required={true}
                            value={data.SITUACAO}
                            onChange={e =>
                              handleChange("SITUACAO", e.target.value)
                            }
                          >
                            <option value={""}>--Selecione--</option>
                            {SITUACAO_GRUPO.map(item => (
                              <option value={item.id}>{item.nome}</option>
                            ))}
                          </InputGroup>
                        </Col>
                      ) : null}
                    </Row>
                  </Card.Body>
                </Skeleton>
              </Card>
            </Page.Container>
          </Col>
        </Row>

        <br />

        <Row>
          <Col sm={12}>
            <Page.Container>
              <Card style={{ width: "100%" }}>
                <Skeleton loading={loading} active>
                  <Card.Header>Dados Gerais</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={6}>
                        <SelectGroup
                          controlId="TIPOS_DESPESAS"
                          label="Tipo Despesa"
                          required
                          error={
                            (!data.TIPOS_DESPESAS ||
                              !data.TIPOS_DESPESAS.length) &&
                            invalidForm
                          }
                        >
                          <SelectTipoDespesa
                            isMulti
                            value={data.TIPOS_DESPESAS}
                            onChange={value =>
                              handleChange("TIPOS_DESPESAS", value)
                            }
                          />
                        </SelectGroup>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="VALOR_PADRAO_HABILITADO">
                          <Form.Label> Despesa Padrão?</Form.Label>
                          <div>
                            <Switch
                              onChange={value =>
                                handleChange("VALOR_PADRAO_HABILITADO", value)
                              }
                              checked={data.VALOR_PADRAO_HABILITADO}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Skeleton>
              </Card>
            </Page.Container>
          </Col>
        </Row>

        {this.hasEnergia ? (
          <Row style={{ marginTop: 20 }}>
            <Col sm={12}>
              <Page.Container>
                <Card style={{ width: "100%" }}>
                  <Skeleton loading={loading} active>
                    <Card.Header>Dados de Unidade Consumidora *</Card.Header>
                    <Card.Body>
                      <Row>
                        <Col sm={4}>
                          <InputGroup
                            controlId="UNIDADE_CONSUMIDORA"
                            disabled={loading}
                            label="Unidade Consumidora"
                            required
                            value={data.UNIDADE_CONSUMIDORA}
                            onChange={e =>
                              handleChange(
                                "UNIDADE_CONSUMIDORA",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Skeleton>
                </Card>
              </Page.Container>
            </Col>
          </Row>
        ) : null}

        {data.VALOR_PADRAO_HABILITADO ? (
          <Row style={{ marginTop: 20 }}>
            <Col sm={12}>
              <Page.Container>
                <Card style={{ width: "100%" }}>
                  <Skeleton loading={loading} active>
                    <Card.Header>Valor Financeiro Pré Definido</Card.Header>
                    <Card.Body>
                      <Row>
                        <Col sm={4}>
                          <Form.Group controlId="VALOR_PADRAO">
                            <Form.Label>
                              Valor Padrão <LabelRequired />
                            </Form.Label>
                            <InputMoney
                              disabled={loading}
                              placeholder="Valor"
                              required
                              value={data.VALOR_PADRAO}
                              onChange={value =>
                                handleChange("VALOR_PADRAO", value)
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Skeleton>
                </Card>
              </Page.Container>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default DadosGerais;
