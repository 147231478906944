import React from "react";
import Page from "../../../../../shared/layout/page";
import Button from "../../../../../shared/fields/Button";
import { Card, Form, Row, Col } from "react-bootstrap";
import SelectComarca from "../../../../../shared/fields/SelectComarca";
import SelectLotacao from "../../../../../shared/fields/SelectLotacao";
import SelectTipoDespesa from "../../../../../shared/fields/SelectTipoDespesa";
import { DatePicker } from "antd"; 

const {  RangePicker } = DatePicker;

class Filtro extends React.PureComponent {
  state = {
    filtros: {},
    loading: false,
    data: {}
  };

  handleChange = dates => {
    const { onChangeFiltro } = this.props;
    onChangeFiltro("DATA", dates);
  };

  handlePanelChange = value => {
    const { onChangeFiltro } = this.props;
    onChangeFiltro("DATA", value);
  };

  render() {
    const { filtro, onChangeFiltro, onFilter, loading, onFormRef } = this.props;

    return (
      <Row>
        <Col sm={12}>
          <Page.Container>
            <Page.Filtro>
              <Card style={{ width: "100%" }}>
                <Card.Body>
                  <Form
                    ref={ref => onFormRef(ref)}
                    
                    onSubmit={e => {
                      e.preventDefault();
                      onFilter();
                    }}
                  >
                    <Row>
                      <Col sm={6}>
                        <Form.Group controlId="grupo">
                          <Form.Label>Grupo</Form.Label>
                          <Form.Control
                            disabled={loading}
                            placeholder="Grupo"
                            value={filtro.GRUPO}
                            onChange={e =>
                              onChangeFiltro("GRUPO", e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group controlId="data">
                          <Form.Label>Data</Form.Label>

                          <RangePicker 
                            format="MM/YYYY"
                            value={filtro.DATA}
                            mode={["month", "month"]}
                            style={{
                              width: "100%",
                              marginTop: 2,
                              height: "36px"
                            }} 
                            onPanelChange={this.handlePanelChange}
                            onChange={e => this.handleChange(e)}
                          /> 
                        </Form.Group> 
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={3}>
                        <Form.Group controlId="tipoDespesa">
                          <Form.Label>Tipo Despesa</Form.Label>
                          <SelectTipoDespesa
                            value={filtro.ID_TIPO_DESPESA}
                            placeholder="--Todas--"
                            onChange={value =>
                              onChangeFiltro("ID_TIPO_DESPESA", value)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="comarca">
                          <Form.Label>Comarca</Form.Label>
                          <SelectComarca
                            value={filtro.ID_COMARCA}
                            placeholder="--Todas--"
                            onChange={value =>
                              onChangeFiltro("ID_COMARCA", value)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="lotacao">
                          <Form.Label>Lotação</Form.Label>
                          <SelectLotacao
                            comarcaId={filtro.ID_COMARCA}
                            value={filtro.ID_LOCACAO}
                            placeholder="--Todas--"
                            onChange={value =>
                              onChangeFiltro("ID_LOCACAO", value)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Label />
                        <Button
                          disabled={loading}
                          block
                          variant="primary"
                          type="submit"
                        >
                          <i className="mdi mdi-file-search" /> Consultar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Page.Filtro>
          </Page.Container>
        </Col>
      </Row>
    );
  }
}

export default Filtro;
