import React from "react";
import Page from "../../../../../shared/layout/page";
import { Row, Col } from "react-bootstrap";
import ErrosGenerico from "./ErrosGenerico";

class Erros extends React.PureComponent {
  render() {
    const { erros } = this.props;

    return (
      <div style={{ marginBottom: erros.length ? 20 : 0 }}>
        <Row>
          <Col sm={12}>
            <Page.Container>
              <ErrosGenerico erros={erros} />
            </Page.Container>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Erros;
