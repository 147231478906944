const INITIAL_STATE = {
    data: null,
}

export default function auth(state = INITIAL_STATE, action) {

    const {type, payload} = action;

    switch (type) {

        case "AUTH.LOGIN_SUCCESS":
            return { data: payload };

        case "AUTH.LOGOUT":
            return { data: null };

        case "AUTH.INITIALIZE":
            return { data: payload };

        default:
            return { ...state }; 
    } 
}