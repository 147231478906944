import React from "react"; 
import { Alert } from "react-bootstrap";

class ErrosGenerico extends React.PureComponent {
  render() {
    const { erros } = this.props;

    return (
      <div style={{ width: "100%" }} >
        {erros.length ? (
          <Alert style={{ width: "100%" }} variant={"danger"}>
            <ul style={{ padding: "0 16px", margin: 0 }}>
              {erros.map((err, key) => (
                <li key={key}>{err}</li>
              ))}
            </ul>
          </Alert>
        ) : null}
      </div>
    );
  }
}

export default ErrosGenerico;
