import React from "react";
import { connect } from "react-redux";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { logout } from "../../../../services/actions/auth.actions";
import history from "../../../../infra/router/history";
import authClient from "../../../../infra/auth/auth-client";

function go(path) {
  history.push(path);
}

function checkPermission(chave, permissao) {
  return authClient.checkPermission(chave, permissao);
}

function NavbarComponent({ auth, logout, isLogged }) {
  return isLogged ? (
    <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand onClick={() => go("/")}>
        <img src="/images/logo.png" alt="Logo" className="navbar-logo" />
        Controle de Despesas
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {checkPermission("CADASTRO_GRUPO", "consultar") ? (
            <NavDropdown title="Cadastro">
              <NavDropdown.Item onClick={() => go("/cadastro/grupo")}>
                Cadastro de Grupo
              </NavDropdown.Item>
            </NavDropdown>
          ) : null}
          {checkPermission("LANCAMENTO_DESPESA", "consultar") ? (
            <NavDropdown title="Lançamento">
              <NavDropdown.Item onClick={() => go("/lancamento/despesa")}>
                Lançar Despesa
              </NavDropdown.Item>
            </NavDropdown>
          ) : null}
        </Nav>

        <Nav className="mr-right">
          <Navbar.Text style={{ marginRight: 16 }}>
            <i className="mdi mdi-account-circle" /> {auth.nome}{" "}
          </Navbar.Text>
          <Nav.Link onClick={() => logout()}>
            <i className="mdi mdi-logout" /> Sair
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  ) : null;
}

const mapStateToProps = state => {
  const auth = (state.auth && state.auth.data) || {};

  const isLogged = !!auth.token;

  return {
    auth,
    isLogged
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  async logout() {
    dispatch(logout());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarComponent);
