import React from "react";
import { Table, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import Paginate from "./Paginate";
import Confirm from "./Confirm";
import { Skeleton, message } from "antd";
import Button from "../fields/Button";

class SimpleGrid extends React.PureComponent {
  state = {
    showExcluir: false,
    loadingExcluir: false,
    item: {},
    per_page: 10,
    current_page: 1,
    columns: []
  };

  componentDidMount() {
    let { columns, onEdit, onDelete, permissionRoot } = this.props;

    let actions = [];

    if (typeof onEdit == "function") {
      actions.push({
        title: "Editar",
        content: (
          <Button
            allowed={permissionRoot + ".alterar"}
            variant="primary"
            size="sm"
          >
            <i className="mdi mdi-pencil" />
          </Button>
        ),
        onClick: value => onEdit(value)
      });
    }

    if (typeof onDelete == "function") {
      actions.push({
        title: "Excluir",
        content: (
          <Button
            allowed={permissionRoot + ".excluir"}
            variant="danger"
            size="sm"
          >
            <i className="mdi mdi-delete" />
          </Button>
        ),
        onClick: value => this.onDelete(value)
      });
    }

    if (actions.length) {
      columns.push({
        title: "Açōes",
        style: {
          width: 60 * actions.length,
          textAlign: "center"
        },
        render: value => {
          return actions.map(ac => {
            const Component = ac.content;
            return (
              <OverlayTrigger overlay={<Tooltip>{ac.title}</Tooltip>}>
                <span
                  style={{
                    display: "block",
                    cursor: "pointer",
                    float: "left",
                    padding: "0 6px"
                  }}
                  onClick={() => ac.onClick(value)}
                >
                  {Component}
                </span>
              </OverlayTrigger>
            );
          });
        }
      });
    }

    this.setState({
      columns: [...columns]
    });
  }

  onDelete(row) {
    this.setState({
      showExcluir: true,
      item: row
    });
    //onDelete(value)
  }

  async confirmDelete() {
    this.setState({
      loadingExcluir: true
    });

    const value = this.state.item;

    let result = await this.props.onDelete(value);

    if (result && result.message) {
      message.success(result.message);
    } else {
      message.success("Exclusão realizada com sucesso!");
    }

    this.setState({
      loadingExcluir: false,
      showExcluir: false
    });
  }

  getCol(value, col) {
    if (col.render) {
      return col.render(value);
    }
    return value[col.name];
  }

  setPerPage(value) {
    this.setState({
      per_page: value
    });

    this.props.onChangeFilter("per_page", value);
  }

  setCurrentPage(value) {
    this.setState({
      current_page: value
    });

    this.props.onChangeFilter("page", value);
  }

  getRangePages() {
    let { data } = this.props;

    let pages = [];

    for (let i = 1; i <= data.last_page; i++) {
      pages.push(i);
    }

    return pages;
  }

  render() {
    let { data, noPaginate, loading, onChangeFilter } = this.props;
    const {
      showExcluir,
      columns,
      loadingExcluir,
      per_page,
      current_page
    } = this.state;

    data = data || {};

    data.data = data.data || [];

    return (
      <div>
        <Confirm
          loading={loadingExcluir}
          show={showExcluir}
          onCancel={() =>
            this.setState({
              loadingExcluir: false,
              showExcluir: false,
              item: {}
            })
          }
          onOk={() => this.confirmDelete()}
          okIcon="delete"
          okType="danger"
        />
        <Skeleton loading={loading} active>
          <Table striped bordered hover size="sm" responsive="sm">
            <thead>
              <tr>
                {columns.map(th => (
                  <th style={{ ...(th.style || {}) }}>{th.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.data.map((val, key) => (
                <tr key={key}>
                  {columns.map((col, k) => (
                    <td key={k} style={{ ...(col.style || {}) }}>
                      {this.getCol(val, col)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>

            {!noPaginate ? (
              <tfoot>
                <tr>
                  <td colSpan={columns.length} style={{ padding: "8px" }}>
                    <div
                      style={{
                        float: "left",
                        padding: "6px 0",
                        fontSize: 14
                      }}
                    >
                      Página
                      <Form.Control
                        size="sm"
                        as="select"
                        style={{
                          width: 60,
                          marginLeft: 10,
                          marginRight: 10,
                          display: "inline-block"
                        }}
                        value={current_page}
                        onChange={e => this.setCurrentPage(e.target.value)}
                      >
                        {this.getRangePages().map(item => (
                          <option value={item}>{item} </option>
                        ))}
                      </Form.Control>
                      de {data.last_page} página(s) com um total de{" "}
                      <strong> {data.total}</strong> registro(s).
                    </div>

                    <div
                      style={{
                        float: "right"
                      }}
                    >
                      <Form.Control
                        size="sm"
                        as="select"
                        style={{
                          width: 120,
                          marginTop: 3,
                          marginRight: 10,
                          float: "left"
                        }}
                        value={per_page}
                        onChange={e => this.setPerPage(e.target.value)}
                      >
                        <option value="10">10 Linhas</option>
                        <option value="20">20 Linhas</option>
                        <option value="30">30 Linhas</option>
                        <option value="40">40 Linhas</option>
                      </Form.Control>
                      <div
                        style={{
                          float: "left"
                        }}
                      >
                        <Paginate
                          total={data.total}
                          per_page={data.per_page}
                          current_page={data.current_page}
                          onChange={page =>
                            onChangeFilter && onChangeFilter("page", page)
                          }
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            ) : null}
          </Table>
        </Skeleton>
      </div>
    );
  }
}

export default SimpleGrid;
