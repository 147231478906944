import React from "react";
import Button from "../../../../../shared/fields/Button";
import SITUACAO_GRUPO from "../../../../../../services/static/SituacaoGrupo";
import { Card, Form, Row, Col } from "react-bootstrap";
import SelectComarca from "../../../../../shared/fields/SelectComarca";
import SelectLotacao from "../../../../../shared/fields/SelectLotacao";
import SelectTipoDespesa from "../../../../../shared/fields/SelectTipoDespesa";

class Filtro extends React.PureComponent {
  render() {
    const { filtro, onChangeFiltro, onFilter, onFormRef, loading } = this.props;

    return (
      <Card style={{ width: "100%" }}>
        <Form
          ref={ref => onFormRef(ref)}
          onSubmit={e => {
            e.preventDefault();
            onFilter(e);
          }}
        >
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>GRUPO</Form.Label>
                  <Form.Control
                    disabled={loading}
                    placeholder="Grupo"
                    value={filtro.GRUPO}
                    onChange={e => onChangeFiltro("GRUPO", e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>SITUAÇÃO</Form.Label>
                  <Form.Control
                    as="select"
                    disabled={loading}
                    placeholder="Situação"
                    value={filtro.SITUACAO}
                    onChange={e => onChangeFiltro("SITUACAO", e.target.value)}
                  >
                    <option value={""}>Todos</option>
                    {SITUACAO_GRUPO.map(item => (
                      <option value={item.id}>{item.nome}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Button
                  disabled={loading}
                  block
                  variant="primary"
                  type="submit"
                  style={{
                    marginTop: 22
                  }}
                >
                  <i className="mdi mdi-file-search" /> Consultar
                </Button>
              </Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>TIPO DE DESPESA</Form.Label>
                  <SelectTipoDespesa
                    value={filtro.ID_TIPO_DESPESA}
                    placeholder="--Todas--"
                    onChange={value => onChangeFiltro("ID_TIPO_DESPESA", value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>COMARCA</Form.Label>
                  <SelectComarca
                    value={filtro.ID_COMARCA}
                    placeholder="--Todas--"
                    onChange={value => onChangeFiltro("ID_COMARCA", value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>LOTAÇÃO</Form.Label>
                  <SelectLotacao
                    comarcaId={filtro.ID_COMARCA}
                    value={filtro.ID_LOTACAO}
                    placeholder="--Todas--"
                    onChange={value => onChangeFiltro("ID_LOTACAO", value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Form>
      </Card>
    );
  }
}

export default Filtro;
