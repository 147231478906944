import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import authClient from "../../../infra/auth/auth-client";

class ButtonComponent extends React.PureComponent {
  render() {
    const { allowed } = this.props;
    let outherProps = {};

    if (allowed !== undefined) {
      const alloweds = allowed.split(".");

      if (!authClient.checkPermission(alloweds[0], alloweds[1])) {
        outherProps = {
          className: "disabled",
          onClick: () => {}
        };

        return (
          <OverlayTrigger
            overlay={
              <Tooltip>
                Você não possui permissão para acessar este recurso!
              </Tooltip>
            }
          >
            <Button {...this.props} {...outherProps} />
          </OverlayTrigger>
        );
      }
    }

    return <Button {...this.props} />;
  }
}

export default ButtonComponent;
