import React from "react";
import { Form } from "react-bootstrap";
import LabelRequired from "./LabelRequired";

class InputGroup extends React.PureComponent {
  render() {
    const { label, controlId, ...props } = this.props;

    return (
      <Form.Group controlId={controlId}>
        <Form.Label> 
          {label} {props.required && <LabelRequired />}
        </Form.Label>
        <Form.Control placeholder={label} {...props} />
      </Form.Group>
    );
  }
}

export default InputGroup;
