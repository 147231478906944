const dados = [
    {
      id: "1",
      nome: "Ativo"
    },
    {
      id: "2",
      nome: "Inativo"
    }
  ];
  
  export default dados;
  