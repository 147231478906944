import React from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import SelectGroup from "../../../../../shared/fields/SelectGroup";
import SelectTipoDespesa from "../../../../../shared/fields/SelectTipoDespesa";
import { DatePicker } from "antd";
import InputGroup from "../../../../../shared/fields/InputGroup";
import axios from "axios";
import moment from "moment";
import ErrosGenerico from "./ErrosGenerico";
import { Alert } from "react-bootstrap";
import ModalAlterarTodasJustificativas from "./ModalAlterarTodasJustificativas";

const { MonthPicker } = DatePicker;

class ModalImportacao extends React.PureComponent {
  state = {
    loading: false,
    invalidForm: false,
    data: {},
    erros: [],
    openJustificativa: false,
    messageSuccess: ""
  };

  componentWillMount() {
    this.setState({
      data: this.props.data
    });
  }

  async onSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      loading: true,
      messageSuccess: ""
    });

    try {
      const form = new FormData();

      form.append(
        "MES_REFERENCIA",
        moment(this.state.data.MES_ANO).format("YYYY-MM")
      );
      form.append("JUSTIFICATIVA", this.state.data.JUSTIFICATIVA);
      form.append("despesas", this.state.data.ARQUIVO);

      const config = {
        onUploadProgress: function(progressEvent) {
          Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
      };

      const { data } = await axios.post(
        "/lancamento/importacao-despesa",
        form,
        config
      );

      let messageSuccess = "Importação realizada com sucesso!";

      if (data.DUPLICADOS && data.DUPLICADOS.length) {
        messageSuccess +=
          "\n Foram detectadas algumas unidades cosumidoras duplicadas (" +
          data.DUPLICADOS.join(", ") +
          ") e as mesmas não foram importadas.";
      }
      if (data.JA_IMPORTADOS && data.JA_IMPORTADOS.length) {
        messageSuccess +=
          "\n Foram detectadas que alguns grupos já contém lançamentos deste tipo para o período selecionado e os mesmos não foram importados.";
      }

      this.setState({ messageSuccess });

      this.props.onSaved(data);

      //INSERIDOS
    } catch (error) {
      let { response } = error;

      if (response && response.status === 422) {
        let erros = [];

        for (const prop in response.data.details) {
          if (prop == "JUSTIFICATIVA") {
            this.setState({
              openJustificativa: true
            });
          }
          erros.push(response.data.details[prop]);
        }

        this.setState({
          erros: [...erros]
        });
      } else {
        this.setState({
          erros: [
            "Houve um problema ao realizar a operação. Entre em contato com algum técnico do sistema."
          ]
        });
      }
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  async continuarImportacao(saved) {
    this.setState({ openJustificativa: false });

    if (!saved) {
      return;
    }

    if (this.state.data.JUSTIFICATIVA) {
      this.onSubmit();
    }
  }

  handleChange(prop, value) {
    const { data } = this.state;

    data[prop] = value;

    this.setState({
      data: {
        ...data
      }
    });
  }

  handleUpload(event) {
    this.setState({
      data: {
        ...this.state.data,
        ARQUIVO: event.target.files[0]
      }
    });
  }

  render() {
    const { open, onClose } = this.props;
    const {
      loading,
      data,
      invalidForm,
      erros,
      messageSuccess,
      openJustificativa
    } = this.state;

    const handleChange = this.handleChange.bind(this);
    const handleUpload = this.handleUpload.bind(this);

    return (
      <div>
        <ModalAlterarTodasJustificativas
          open={openJustificativa}
          onChange={value =>
            this.setState({
              data: {
                ...data,
                JUSTIFICATIVA: value
              }
            })
          }
          onClose={saved => this.continuarImportacao(saved)}
        />

        <Modal size="lg" show={open} onHide={onClose}>
          <Form onSubmit={e => this.onSubmit(e)}>
            <Modal.Header closeButton>
              <Modal.Title>{"Importar Lançamentos"}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col sm={12}>
                  {messageSuccess ? (
                    <Alert style={{ width: "100%" }} variant={"success"}>
                      {messageSuccess}
                    </Alert>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <ErrosGenerico erros={erros} />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <SelectGroup
                    controlId="ID_TIPO_DESPESA"
                    label="Tipo Despesa"
                    disabled={true}
                    error={!data.ID_TIPO_DESPESA && invalidForm}
                  >
                    <SelectTipoDespesa
                      disabled={true}
                      value={data.ID_TIPO_DESPESA}
                      onChange={value => handleChange("ID_TIPO_DESPESA", value)}
                    />
                  </SelectGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <SelectGroup
                    controlId="MES_ANO"
                    label="Mês/Ano"
                    error={!data.MES_ANO && invalidForm}
                  >
                    <MonthPicker
                      format="MM/YYYY"
                      disabled={true}
                      style={{
                        width: "100%",
                        marginTop: 2,
                        height: "36px"
                      }}
                      value={data.MES_ANO}
                      onChange={value => handleChange("MES_ANO", value)}
                      placeholder="Selecione..."
                    />
                  </SelectGroup>
                </Col>
                <Col sm={6}>
                  <InputGroup
                    controlId="ARQUIVO"
                    type="file"
                    disabled={loading}
                    label="Arquivo"
                    required
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleUpload}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: "32px" }}>
                <Col sm={12}>
                  <Alert variant="info">
                    A importação aceita somente arquivos no formato de excel
                    (.xls ou .xlsx).
                  </Alert>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                onClick={() => onClose()}
                variant="secondary"
              >
                Cancelar
              </Button>
              <Button type="submit" disabled={loading} variant={"success"}>
                <i className={"mdi mdi-upload"} />
                {loading ? "Importando..." : "Importar"}
              </Button>

              {messageSuccess ? (
                <Button
                  type="button"
                  onClick={() => onClose()}
                  variant="primary"
                >
                  Confirmar
                </Button>
              ) : null}
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default ModalImportacao;
