import React from "react";
import Page from "../../../../shared/layout/page";
import Button from "../../../../shared/fields/Button";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import DadosGerais from "./components/DadosGerais";
import TabelaLancamento from "./components/TabelaLancamento";
import { Form } from "react-bootstrap";
import { notification, Modal, message } from "antd";
import Erros from "./components/Erros";
import ModalImportacao from "./components/ModalImportacao";

const selfWindow = window;

class DespesaPage extends React.Component {
  state = {
    filtros: {},
    loading: false,
    erros: [],
    disabledPelaImportacao: false,
    openImportar: false,
    invalidForm: false,
    data: {
      GRUPOS: [],
      MES_ANO: moment()
    },
    dataOld: {
      GRUPOS: []
    }
  };

  async componentDidMount() {
    this.onLancar = this.onLancar.bind(this);

    const id = this.props.match.params.id;

    this.setState({ id });

    if (id) {
      setTimeout(() => this.getData(id), 200);
    }
  }

  handleChange(prop, value) {
    const { data } = this.state;

    if (prop === "ID_TIPO_DESPESA") {
      data.GRUPOS = [];
    }

    data[prop] = value;

    this.setState({
      data: {
        ...data
      }
    });
  }

  validarLancamento(data) {
    const { parametros, permissoes } = this.props;

    let erros = [];

    if (!data.GRUPOS.length) {
      erros.push(`Você precisa informar pelo menos um lançamento com valor.`);
    }

    let lancamentoNovosComAlteracao = data.GRUPOS.filter(x => !x.ID_LANCAMENTO);

    if (lancamentoNovosComAlteracao.length) {
      // removo os que estao com valores zerados
      let lancamentosComValoresZerados = lancamentoNovosComAlteracao.filter(
        x => !x.VALOR_FINANCEIRO
      );

      if (lancamentosComValoresZerados.length) {
        erros.push(
          `Você precisa informar pelo menos um lançamento novo com valor.`
        );
      }
    }

    const ano_mes = moment().format("YYYY-MM");

    if (
      moment(data.DATA_LANCAMENTO).isAfter(
        moment(ano_mes + "-" + parametros.DIA_UTIL_LANCAMENTO)
      ) ||
      moment(data.DATA_LANCAMENTO).isBefore(moment(ano_mes + "-01"))
    ) {
      // Caso tenha não permissão
      if (
        !permissoes.LANCAMENTO_DESPESA_FORA_PERIODO ||
        !permissoes.LANCAMENTO_DESPESA_FORA_PERIODO.incluir
      ) {
        erros.push(
          "Você não possui permissão para realizar lançamentos fora do período válido!"
        );
      }
    }

    this.setState({ erros });

    return erros.length;
  }

  async save(e) {
    e.preventDefault();
    e.stopPropagation();

    const { data, dataOld, id } = this.state;

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      this.setState({
        invalidForm: true
      });
      return;
    }

    let saveData = { ...data };

    // Verifica alteração de algum lançamento
    saveData.GRUPOS = saveData.GRUPOS.filter(item => {
      if (item.ID_LANCAMENTO) {
        const itemOld = dataOld.GRUPOS.filter(
          x => x.ID_LANCAMENTO == item.ID_LANCAMENTO
        )[0];

        // Caso nao tenha justificativa e ja tenha sido alterado ou seja dado antigo nao faça nada
        if (
          (!item.JUSTIFICATIVA && !itemOld.JUSTIFICATIVA) ||
          itemOld.JUSTIFICATIVA == item.JUSTIFICATIVA
        ) {
          return false;
        }
      }

      return true;
    });

    const validacao = this.validarLancamento(saveData);

    if (validacao) {
      this.setState({
        invalidForm: true
      });
      return;
    }

    saveData.GRUPOS = saveData.GRUPOS.filter(item => item.VALOR_FINANCEIRO > 0);

    this.setState({
      loading: true,
      erros: [],
      invalidForm: false
    });

    saveData.MES = moment(saveData.MES_ANO).format("MM");
    saveData.ANO = moment(saveData.MES_ANO).format("YYYY");

    try {
      if (id) {
        await axios.put("/lancamento/" + id, saveData);
      } else {
        await axios.post("/lancamento", saveData);
      }

      message.success("Registro salvo com sucesso!");

      this.props.history.push("/lancamento/despesa");
    } catch (error) {
      let { response } = error;

      if (response && response.status === 422) {
        let erros = [];

        for (const prop in response.data.details) {
          erros.push(response.data.details[prop]);
        }

        this.setState({
          erros: [...erros]
        });
      } else {
        this.setState({
          erros: [
            "Houve um problema ao realizar a operação. Entre em contato com algum técnico do sistema."
          ]
        });
      }
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  async getData(id) {
    this.setState({
      loading: true
    });

    try {
      const { data } = await axios.get("/lancamento/" + id);

      data.MES_ANO =
        data.ANO + "-" + (data.MES <= 9 ? "0" + data.MES : data.MES) + "-01";

      data.MES_ANO = moment(data.MES_ANO);

      this.setState({
        data: { ...data },
        dataOld: { ...data }
      });
    } catch (e) {
      if (e.message.indexOf("404") > -1) {
        notification.warn({
          message: "Aviso",
          description: "A despesa selecionada não existe."
        });
        this.props.history.replace("/lancamento/despesa");
      }
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  async onLancar() {
    const dataAtual = { ...this.state.data };

    this.setState({
      erros: []
    });

    if (!dataAtual.ID_TIPO_DESPESA) {
      this.setState({
        erros: ["Selecione um tipo de despesa para continuar."]
      });
      return;
    }

    if (!dataAtual.MES_ANO) {
      this.setState({
        erros: ["Selecione o mês de referência do lançamento para continuar."]
      });
      return;
    }

    this.setState({
      loading: true
    });

    try {
      const { data } = await axios.get("/grupo/lancamento", {
        params: {
          ID_TIPO_DESPESA: dataAtual.ID_TIPO_DESPESA,
          MES_ANO: moment(dataAtual.MES_ANO).format("YYYY-MM"),
          per_page: -1,
          GRUPOS_JA_ADICIONADOS: dataAtual.GRUPOS.map(x => x.ID_GRUPO).join(",")
        }
      });

      dataAtual.GRUPOS = [...dataAtual.GRUPOS, ...data];

      this.setState({
        data: {
          ...dataAtual
        },
        dataOld: {
          GRUPOS: JSON.parse(JSON.stringify([...data]))
        }
      });

      // Crio os eventos do teclado para caminhar sobre os elementos
      setTimeout(() => {
        dataAtual.GRUPOS.forEach((item, index) => {
          selfWindow.document
            .querySelector("#id-" + index)
            .addEventListener("keydown", function(ev) {
              if (ev.keyCode === 13) {
                ev.stopPropagation();
                ev.preventDefault();

                let nextElement = document.querySelector("#id-" + (index + 1));

                if (nextElement) {
                  nextElement.focus();
                }
              }
            });
        }, 300);
      });
    } catch (e) {
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  async openImportar() {
    const { data } = this.state;

    if (data.ID_TIPO_DESPESA !== 7) {
      Modal.confirm({
        content:
          "O Tipo de despesa sera alterado para Energia após, deseja realmente continuar ?",
        onOk: () => {
          this.handleChange("ID_TIPO_DESPESA", 7);

          this.setState({
            openImportar: true
          });
        }
      });
    } else {
      this.setState({
        openImportar: true
      });
    }
  }

  async closeImportar() {
    this.setState({
      openImportar: false
    });
  }

  async voltar() {
    Modal.confirm({
      title: "Aviso",
      content: "Deseja realmente voltar? Dados não salvo serão perdidos!",
      onOk: () => {
        this.props.history.push("/lancamento/despesa");
      }
    });
  }

  render() {
    const {
      loading,
      data,
      erros,
      invalidForm,
      openImportar,
      disabledPelaImportacao
    } = this.state;

    return (
      <Page>
        {openImportar ? (
          <ModalImportacao
            data={data}
            onSaved={response => {
              this.setState({ disabledPelaImportacao: true });
              this.handleChange("GRUPOS", response.INSERIDOS);
            }}
            open={openImportar}
            onClose={() => this.closeImportar()}
          />
        ) : null}

        <Form noValidate validated={invalidForm} onSubmit={e => this.save(e)}>
          <Page.Header>
            <Page.Container>
              <Page.Header.Title>
                <h3> Lançamento de Despesas </h3>
              </Page.Header.Title>
              <Page.Header.Right>
                <Button
                  variant="secondary"
                  type="button"
                  style={{
                    marginRight: 16
                  }}
                  onClick={() => this.voltar()}
                >
                  <i className="mdi mdi-arrow-left" /> Voltar
                </Button>
                <Button
                  style={{
                    marginRight: 16
                  }}
                  onClick={() => this.openImportar()}
                  disabled={loading}
                  type="button"
                  variant="primary"
                >
                  <i className="mdi mdi-file-excel" />
                  Importar Valores
                </Button>
                <Button disabled={loading} type="submit" variant="success">
                  Salvar
                </Button>
              </Page.Header.Right>
            </Page.Container>
          </Page.Header>

          <Erros erros={erros} />

          <DadosGerais
            data={data}
            disabledPelaImportacao={disabledPelaImportacao}
            loading={loading}
            invalidForm={invalidForm}
            onLancar={() => this.onLancar()}
            handleChange={(prop, value) => this.handleChange(prop, value)}
          />

          <br />

          <TabelaLancamento
            data={data}
            loading={loading}
            handleChange={(prop, value) => this.handleChange(prop, value)}
          />
        </Form>
      </Page>
    );
  }
}

// DespesaPage.prototype = {
//   children : Prototype.fun,
// }

const mapStateToProps = state => {
  const auth = state.auth.data || {};
  const permissoes = auth.permissoes || {};
  const parametros = auth.parametros || {};

  return { parametros, permissoes };
};

export default connect(
  mapStateToProps,
  null
)(DespesaPage);
