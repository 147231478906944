import React from "react";
import "./page.scss";


function Container({children}) {
 
    return(
        <div className={"page-container"}>
           {children}
        </div>
    )
}


export default Container;