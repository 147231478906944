import React from "react";
import axios from "axios"; 
import authClient from "../../../infra/auth/auth-client";
import Select from "./Select";

class SelectTipoDespesa extends React.PureComponent {
  state = {
    dados: [],
    loading: false
  };

  async componentWillMount() {
    this.setState({
      loading: true
    });
    const token = (authClient.getData() || {}).token;

    const response = await axios.get( "/tipo-despesa", {
      headers: {
        "X-Token": token
      }
    });

    this.setState({
      dados: response.data,
      loading: false
    });
  }

  getOptions() {
    let options = this.state.dados.map(x => {
      return {
        value: x.id,
        label: x.descricao
      };
    });

    return options;
  }

  render() {
    const { loading } = this.state;
    const { ...props } = this.props;

    return (
      <Select 
        loading={loading}
        {...props}
        options={this.getOptions()}
      />
    );
  }
}

export default SelectTipoDespesa;
