import React from "react";
import { withRouter } from "react-router";
import { Modal, Button } from "react-bootstrap";

class Confirm extends React.Component {
  render() {
    const {
      title,
      content,
      show,
      onOk,
      onCancel,
      okIcon,
      okType,
      loading
    } = this.props;

    return (
      <div>
        <Modal show={show} onHide={onCancel}>
          <Modal.Header closeButton>
            <Modal.Title>{title || "Aviso"}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{content || "Deseja realizar esta operação?"}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={onCancel} variant="secondary">
              Cancelar
            </Button>
            <Button disabled={loading} onClick={onOk} variant={okType}>
              <i className={"mdi mdi-" + okIcon} /> Confirmar
            </Button>
          </Modal.Footer>
        </Modal> 
      </div>
    );
  }
}

export default withRouter(Confirm);
