import React from "react";
import Page from "../../../../../shared/layout/page";
import { Row, Col, Alert } from "react-bootstrap";

function Erros({ erros }) {
  return (
    <div style={{ marginBottom: erros.length ? 20 : 0 }}>
      <Row>
        <Col sm={12}>
          <Page.Container>
            {erros.length ? (
              <Alert style={{ width: "100%" }} variant={"danger"}>
                <ul style={{ padding: "0 16px", margin: 0 }}>
                  {erros.map((err, key) => (
                    <li key={key}>{err}</li>
                  ))}
                </ul>
              </Alert>
            ) : null}
          </Page.Container>
        </Col>
      </Row>
    </div>
  );
}

export default Erros;
