import React from "react";
import LoginPage from "./LoginPage";
import { Route, Switch } from "react-router-dom";

function Routes() {

    return (
        <Switch>
            <Route exact path="/login" component={LoginPage} />
        </Switch>
    )
}

export default Routes;