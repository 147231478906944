import React from "react";
import { Form } from "react-bootstrap";
import LabelRequired from "./LabelRequired";

class SelectGroup extends React.PureComponent {
  state = {
    dirty: false
  };

  render() {
    const { label, controlId, error, ...props } = this.props;

    return (
      <Form.Group controlId={controlId}>
        <Form.Label>
          {label} {props.required && <LabelRequired />}
        </Form.Label>
        <div className={error ? "select-group-error" : ""}>
          {props.children}
        </div>
      </Form.Group>
    );
  }
}

export default SelectGroup;
