import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import authClient from "./infra/auth/auth-client";
import history from "./infra/router/history";
import { BASE_URL } from "./infra/env";
import { notification } from "antd";

axios.defaults.baseURL = BASE_URL;

axios.interceptors.request.use(
  config => {
    const token = (authClient.getData() || {}).token;

    if (token) {
      config.headers.common["X-Token"] = token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const statusCode = error.response && error.response.status;

    if (statusCode === 401) {
      if (error.config.url.indexOf("v1/login") === -1) {
        notification.error({
          message: "Aviso",
          description: "Faça login para continuar no sistema!"
        });

        authClient.logout();

        history.replace("/login");
        return;
      }
    } else {
      if (statusCode === 403) {
        notification.error({
          message: "Aviso",
          description: error.response.data.message
        });
      }
      if (statusCode === 500) {
        notification.warn({
          message: "Aviso",
          description:
            "Houve um problema ao realizar está operação, por favor entre em contato com algum técnico do sistema."
        });
      }
    }

    return Promise.reject(error);
  }
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
