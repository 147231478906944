import React from "react";
import Page from "../../../../../shared/layout/page";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Table, Skeleton } from "antd";
import SelectComarca from "../../../../../shared/fields/SelectComarca";
import SelectLotacao from "../../../../../shared/fields/SelectLotacao";
import moment from "moment";
import Confirm from "../../../../../shared/data/Confirm";
import LabelRequired from "../../../../../shared/fields/LabelRequired";

class VincularComarca extends React.PureComponent {
  state = {
    COMARCA: null,
    LOTACAO: null,
    itemExcluido: {},
    showExcluir: false
  };

  adicionar() {
    if (!this.state.COMARCA || !this.state.LOTACAO) return;

    const data = { ...this.props.data };
    data.VINCULOS = data.VINCULOS || [];
    const LOTACAO = this.state.LOTACAO;
    const COMARCA = this.state.COMARCA;

    let estaComarcaJaPossuiAlgumVinculo = data.VINCULOS.filter(
      x => x.ID_COMARCA === COMARCA.value
    )[0];

    if (estaComarcaJaPossuiAlgumVinculo) {
      data.VINCULOS.forEach(item => {
        if (item.ID_COMARCA === COMARCA.value) {
          LOTACAO.forEach(lotacao => {
            let lotacaoExistente =
              item.LOTACOES.map(x => x.ID_LOTACAO).indexOf(lotacao.value) ===
              -1;

            if (lotacaoExistente) {
              item.LOTACOES.push({
                ID_LOTACAO: lotacao.value,
                LOTACAO: lotacao.label,
                DATA_CRIACAO: moment().format("YYYY-MM-DD")
              });
            }
          });
        }
      });
    } else {
      data.VINCULOS.push({
        ID_COMARCA: COMARCA.value,
        COMARCA: COMARCA.label,
        DATA_CRIACAO: moment().format("YYYY-MM-DD"),
        LOTACOES: LOTACAO.map(lot => {
          return {
            LOTACAO: lot.label,
            ID_LOTACAO: lot.value,
            DATA_CRIACAO: moment().format("YYYY-MM-DD")
          };
        })
      });
    }

    this.props.handleChange("VINCULOS", [...data.VINCULOS]);

    this.setState({
      LOTACAO: null,
      COMARCA: null
    });
  }

  removerItem = (comarcaId, lotacaoId = null) => {
    this.setState({
      itemExcluido: {
        comarcaId,
        lotacaoId
      },
      showExcluir: true
    });
  };

  confirmDelete = () => {
    const { itemExcluido } = this.state;
    const { comarcaId, lotacaoId } = itemExcluido;

    const data = { ...this.props.data };
    let excluidos = [];

    // exclusao do nó da comarca toda
    if (lotacaoId == null) {
      const comarcaExcluida = data.VINCULOS.filter(
        x => x.ID_COMARCA === comarcaId
      )[0];

      // Pega as lotaçōes que estão dentro da comarca que esta sendo excluida
      excluidos = comarcaExcluida.LOTACOES.map(x => x.ID_LOTACAO);

      // Remove os itens
      data.VINCULOS.forEach(x => {
        if (x.ID_COMARCA === comarcaId) {
          x.LOTACOES = [];
        }
      });
    } else {
      // exclusao de uma lotação
      data.VINCULOS.forEach(comarca => {
        if (comarca.ID_COMARCA === comarcaId) {
          // Pega as lotaçōes que estão sendo excluidas
          excluidos = comarca.LOTACOES.filter(
            x => x.ID_LOTACAO === lotacaoId
          ).map(x => x.ID_LOTACAO);

          // Remove os itens
          comarca.LOTACOES = comarca.LOTACOES.filter(
            x => x.ID_LOTACAO !== lotacaoId
          );
        }
      });
    }

    const VINCULOS = [...data.VINCULOS.filter(x => x.LOTACOES.length)];

    this.props.handleChange("VINCULOS", [...VINCULOS]);
    this.props.handleChange("VINCULOS_EXCLUIDOS", excluidos);

    this.setState({
      showExcluir: false,
      itemExcluido: {}
    });
  };

  handleChange(prop, value) {
    this.setState({
      [prop]: value
    });
  }

  getLotacoesByComarca = () => {
    const data = { ...this.props.data };
    data.VINCULOS = data.VINCULOS || [];

    let lotacoes = [];

    data.VINCULOS.forEach(item => {
      lotacoes = item.LOTACOES.map(x => x.ID_LOTACAO);
    });

    return lotacoes.join(",");
  };

  render() {
    const state = this.state;
    const { data, loading } = this.props;
    const { showExcluir, itemExcluido } = state;

    const handleChangeLocal = this.handleChange.bind(this);

    return (
      <div>
        <Confirm
          show={showExcluir}
          content={
            "Deseja realmente excluir a " +
            (itemExcluido.lotacaoId ? "Lotação?" : "Comarca?")
          }
          onCancel={() =>
            this.setState({
              loadingExcluir: false,
              showExcluir: false,
              itemExcluido: {}
            })
          }
          onOk={() => this.confirmDelete()}
          okIcon="delete"
          okType="danger"
        />

        <Row>
          <Col sm={12}>
            <Page.Container>
              <Card style={{ width: "100%" }}>
                <Skeleton loading={loading} active>
                  <Card.Header>Vincular Comarcas e Lotaçōes</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={8}>
                        <Form.Group>
                          <Form.Label>
                            Comarca: <LabelRequired />
                          </Form.Label>
                          <SelectComarca
                            value={state.COMARCA}
                            valueAsObject
                            onChange={value =>
                              handleChangeLocal("COMARCA", value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={8}>
                        <Form.Group>
                          <Form.Label>
                            Lotação: <LabelRequired />
                          </Form.Label>
                          <SelectLotacao
                            value={state.LOTACAO}
                            params={{
                              LOTACOES_USADAS: this.getLotacoesByComarca()
                            }}
                            isMulti
                            sincronizar
                            valueAsObject
                            comarcaId={state.COMARCA && state.COMARCA.value}
                            onChange={value =>
                              handleChangeLocal("LOTACAO", value)
                            }
                          />
                        </Form.Group>

                        <Form.Group>
                          <Button onClick={() => this.adicionar()}>
                            <i className="mdi mdi-plus" /> Adicionar
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Skeleton>
              </Card>
            </Page.Container>
          </Col>
        </Row>

        <br />

        <Row>
          <Col sm={12}>
            <Page.Container>
              <Card style={{ width: "100%" }}>
                <Skeleton loading={loading} active>
                  <Card.Header>Comarcas e Lotaçōes</Card.Header>
                  <Card.Body>
                    <Table
                      pagination={false}
                      columns={[
                        {
                          title: "#",
                          dataIndex: "ID_COMARCA",
                          style: {
                            width: 70,
                            textAlign: "center"
                          }
                        },
                        {
                          title: "Comarca",
                          dataIndex: "COMARCA"
                        },
                        {
                          title: "Data Inclusão",
                          dataIndex: "DATA_CRIACAO",
                          render: value => moment(value).format("DD/MM/YYYY")
                        },
                        {
                          title: "Açōes",
                          width: 90,
                          render: (value, record) => (
                            <Button
                              variant="danger"
                              onClick={() =>
                                this.removerItem(record.ID_COMARCA)
                              }
                              size="sm"
                            >
                              <i className="mdi mdi-delete" />
                            </Button>
                          )
                        }
                      ]}
                      expandedRowRender={record => (
                        <Table
                          pagination={false}
                          size="small"
                          bordered
                          columns={[
                            {
                              title: "#",
                              dataIndex: "ID_LOTACAO",
                              style: {
                                width: 70,
                                textAlign: "center"
                              }
                            },
                            {
                              title: "Lotação",
                              dataIndex: "LOTACAO"
                            },
                            {
                              title: "Data Inclusão",
                              dataIndex: "DATA_CRIACAO",
                              render: value =>
                                moment(value).format("DD/MM/YYYY")
                            },
                            {
                              title: "Açōes",
                              width: 90,
                              render: (value, lotacao) => (
                                <Button
                                  variant="danger"
                                  onClick={() =>
                                    this.removerItem(
                                      record.ID_COMARCA,
                                      lotacao.ID_LOTACAO
                                    )
                                  }
                                  size="sm"
                                >
                                  <i className="mdi mdi-delete" />
                                </Button>
                              )
                            }
                          ]}
                          dataSource={record.LOTACOES}
                        />
                      )}
                      dataSource={data.VINCULOS}
                    />
                  </Card.Body>
                </Skeleton>
              </Card>
            </Page.Container>
          </Col>
        </Row>
      </div>
    );
  }
}

export default VincularComarca;
