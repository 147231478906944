import React from "react";
import ReactSelect from "react-select";

class Select extends React.PureComponent {
  getValue() {
    let { value, options, isMulti, valueAsObject } = this.props;

    if (valueAsObject) return value;

    if (isMulti) {
      value = value || [];

      return options.filter(x => value.indexOf(x.value) > -1);
    }

    if (value === undefined) return null;

    return options.filter(x => x.value === value)[0];
  }

  onChange(value) {
    const { onChange, isMulti, valueAsObject } = this.props;

    if (valueAsObject) {
      return onChange(value);
    }

    if (isMulti) {
      const result = value.map(x => x.value);
      return onChange(result);
    }

    onChange(value ? value.value : null);
  }

  render() {
    const { loading, disabled, ...props } = this.props;

    this.onChange = this.onChange.bind(this);

    return (
      <ReactSelect
        loadingMessage={() => "Carregando..."}
        noOptionsMessage={() => "Nenhum resultado."}
        placeholder="Selecione..."
        isLoading={loading}
        isClearable={true}
        isDisabled={disabled}
        {...props}
        value={this.getValue()}
        onChange={this.onChange}
      />
    );
  }
}

export default Select;
