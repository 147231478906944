import React from "react";
import axios from "axios";
import Select from "./Select";

class SelectComarca extends React.PureComponent {
  state = {
    dados: [],
    loading: false
  };

  async componentWillMount() {
    this.setState({
      loading: true
    });

    const response = await axios.get( "/comarca");

    this.setState({
      dados: response.data,
      loading: false
    });
  }

  getOptions() {
    return this.state.dados.map(x => {
      return {
        value: x.id,
        label: x.descricao
      };
    });
  }

  render() {
    const { loading } = this.state;
    const { ...props } = this.props;

    return <Select loading={loading} {...props} options={this.getOptions()} />;
  }
}

export default SelectComarca;
