import React from "react";
import { Route, Switch, Redirect  } from "react-router-dom";

// Modulos da Aplicacao
import LoginPages from "./Login";
import ErrosPages from "./Erros";
import CadastroPages from "./Cadastro";
import LancamentoPages from "./Lancamento"; 
import authClient from "../../infra/auth/auth-client";
import RouterAuthorize from "../../infra/router/RouterAuthorize";

function PagesRouter() {

    return (
        <Switch>
            <Route path="/login" component={() => authClient.isLogged() ? <Redirect to="/" /> : <LoginPages />} />

            <RouterAuthorize path="/erro" component={ErrosPages} />
            <RouterAuthorize path="/cadastro" component={CadastroPages} />
            <RouterAuthorize path="/lancamento" component={LancamentoPages} /> 
            <RouterAuthorize path="/" component={() => <Redirect to="/lancamento/despesa" />} />

        </Switch>
    )
}


export default PagesRouter;

